@use "sass:map";

$theme-colors: (
  "primary": #779ecb,
  "light": #f8f9fa,
  "dark": #000000,
);

:export {
  primary: map-get($theme-colors, "primary");
}
