@import "./variables.scss";
@import "./backgrounds.scss";
@import "./responsive.scss";
@import "~bootstrap/scss/bootstrap";

.event-item {
  margin-bottom: 2rem;
  border: 2px solid $primary;
}

.events-item-header {
  background-color: $primary;
  color: white;
  border-radius: 0 !important;
}

.events-item-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.text-small {
  font-size: 1rem;
}

.video-card {
  padding: 6rem 0;
  background-color: #2c2f34;
  color: #779ecb;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  border-bottom: 4px solid #779ecb;
  margin: auto;
}

.video-thumbnail {
  margin: 0 0 1rem 0;
  width: 500px;

  img {
    width: 100%;
  }
}

.video-text {
  width: 500px;
  margin: 0 2rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.video-title {
  font-size: clamp(1rem, 5vw, 2rem);
  font-weight: bold;
}

.video-date {
  font-size: clamp(0.75rem, 5vw, 1.5rem);
  margin-top: 5px;
  font-weight: 200;
}

.video-description {
  font-size: clamp(0.75rem, 3vw, 1rem);
  margin-top: 30px;
}
