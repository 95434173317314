@media (max-width: 500px) {
  h1 {
    font-size: 1.5rem !important;
  }

  h3 {
    font-size: 1.25rem !important;
  }

  .header-logo-image {
    width: 6rem !important;
  }
}
